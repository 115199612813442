import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import App from './App'
import { withStyles } from '@material-ui/core/styles'
import AssetsList from '../asset/AssetsList'
import AddAssetDialog from '../asset/AddAssetDialog'
import UsersList from '../user/UsersList'
import AddUserDialog from '../user/AddUserDialog'
import Snackbar from '../util/Snackbar'
import ProgressDialog from '../util/ProgressDialog'
import UserDetailsDialog from '../user/UserDetailsDialog'
import EditAssetDialog from '../asset/EditAssetDialog'
import { cloneDeep } from 'lodash'
import DeleteConfirmationDialog from '../util/DeleteConfirmationDialog'
import Search from '../collection/Search'
import adminApi from '../../store/exhibitAdminApi'
import firebase from 'firebase/compat/app'
import PlatformTop from '../UI/PlatformTop'
import { isAdmin } from '../../store/exhibitSlice'

const defaultAsset = {
  link: '',
  type: 'IMAGE',
  name: '',
  thumbnail: '',
  status: 'ACTIVE',
}

const listedAssetTypes = ['IMAGE', 'VIDEO', 'AUDIO']
const listedAssetTypesQuery = `type=${listedAssetTypes.join('&type=')}`

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  drawerHeader: {
    display: 'flex',
  },
  margin: {
    margin: theme.spacing(1),
  },
  organization: {
    margin: 0,
    padding: '15px 2%',
    background: '#f1f1f1',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    marginTop: 20,
    display: 'flex',
  },
  categoryButton: {
    color: '#06c',
    padding: '8px 15px 6px',
    margin: '0 5px 5px 0',
    borderRadius: 30,
    background: '#06c',
    fontWeight: '700',
    letterSpacing: '-.6px',
  },
  page: {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },
  pageShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 240,
  },
  platformTop: {
    float: 'unset',
    display: 'flex',
    alignItems: 'center',
  },
})

function AppsListPage(props) {
  const orgId = props.match.params.orgId

  const {
    classes,
    handleAppClick,
    handleAddNewClicked,
    org,
    apps,
  } = props
  const [appsSelected, setAppsSelected] = useState(false)
  const [assetsSelected, setAssetsSelected] = useState(false)
  const [usersSelected, setUsersSelected] = useState(false)
  const [addAssetDialogOpen, setAddAssetDialogOpen] = useState(false)
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [showProgress, setShowProgress] = useState(false)
  const [openUserDetails, setOpenUserDetails] = useState({
    open: false,
    user: undefined,
  })
  const [assetSelected, setAssetSelected] = useState({
    open: false,
    asset: defaultAsset,
  })
  const [openDrawer, setOpenDrawer] = useState(false)
  const [assetsToAdd, setAssetsToAdd] = useState([])
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false)
  const [assets, setAssets] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [offset, setOffset] = useState(0)

  const isAppAdmin = useSelector(isAdmin)

  const buttonSelected = 'category_button smbutton secondbg white'
  const buttonUnSelected = 'category_button smbutton whitebg'

  useEffect(() => {
    setAppsSelected(true)
  }, [])

  useEffect(() => {
    if (assetSelected) {
      fetchAssetsWithoutAppending()
    }
  }, [assetsSelected])

  useEffect(() => {
    fetchAssets()
  }, [offset])

  useEffect(() => {
    if (searchValue) {
      searchAssets()
    } else {
      fetchAssetsWithoutAppending()
    }
  }, [searchValue])

  const handleFetchMoreAssets = () => {
    setOffset((prevOffset) => prevOffset + 20)
  }

  async function fetchAssetsWithoutAppending() {
    const assets = await adminApi.asset().getAllByOrgId(orgId, 0, listedAssetTypesQuery)
    setAssets(assets)
  }

  async function fetchAssets() {
    const newAssets = await adminApi.asset().getAllByOrgId(orgId, offset, listedAssetTypesQuery)
    setAssets([...assets, ...newAssets])
  }

  const selectApps = () => {
    setAppsSelected(true)
    setAssetsSelected(false)
    setUsersSelected(false)
  }

  const selectAssets = () => {
    setAssetsSelected(true)
    setAppsSelected(false)
    setUsersSelected(false)
  }

  const selectUsers = () => {
    setAppsSelected(false)
    setAssetsSelected(false)
    setUsersSelected(true)
  }

  const openAddAssetDialog = () => {
    setAddAssetDialogOpen(true)
  }

  const showMessage = (message) => {
    setSnackbarMessage(message)
    setOpenSnackbar(true)
  }

  const showUserDetails = (user) => {
    setOpenUserDetails({
      open: true,
      user: user,
    })
  }

  const handleAssetsSaved = (newAsset) => {
    setAssets([newAsset, ...assets])
  }

  const handleAssetClicked = (asset) => {
    setAssetSelected({
      open: true,
      asset: asset,
    })
  }

  const assetAlreadyAdded = (assetId) => {
    const asset = assetsToAdd.find((asset) => asset._id === assetId)
    return asset !== undefined
  }

  const handleAddToStory = (assetId) => {
    if (assetAlreadyAdded(assetId)) return

    const asset = assets.find((asset) => asset._id === assetId)

    setAssetsToAdd([...assetsToAdd, { ...asset }])
    setOpenDrawer(true)

    const assetsCopy = [...assets]
    const foundAsset = assetsCopy.find((asset) => asset._id === assetId)
    foundAsset.added = true

    setAssets([...assetsCopy])
  }



  const handleChangeAppAccess = (appId) => (e) => {
    const checked = e.target.checked
    const copy = cloneDeep(openUserDetails)
    if (checked) {
      const app = apps.find((a) => a._id === appId)
      if (app) {
        copy.user.apps.push({ ...app })
      }
    } else {
      const index = copy.user.apps.findIndex((a) => a._id === appId)
      if (index !== -1) {
        copy.user.apps.splice(index, 1)
      }
    }

    setOpenUserDetails(copy)
  }

  const handleSaveUserDetails = async () => {
    setShowProgress(true)

    await adminApi.user().update({ user: openUserDetails.user, orgId: org._id })

    showMessage('User updated')
    setShowProgress(false)
    handleCloseUserDetailsDialog()
  }

  const toggleProgress = () => {
    setShowProgress((prevShowProgress) => !prevShowProgress)
  }

  const handleCloseUserDetailsDialog = () => {
    setOpenUserDetails({ open: false, user: undefined })
  }

  const handleChangeOrgAccess = (e) => {
    const orgAccessValue = e.target.value
    const copy = cloneDeep(openUserDetails)
    const value = orgAccessValue === 'admin'
    copy.user.isAdmin = value
    setOpenUserDetails(copy)
  }

  const handleDeleteUser = () => {
    setOpenDeleteUserDialog(true)
  }

  const handleDeleteUserConfirmed = async () => {
    setOpenDeleteUserDialog(false)
    handleCloseUserDetailsDialog()
    toggleProgress()
    const { error } = await deleteUser()
    toggleProgress()
    if (error === undefined) {
      // User successfully deleted
      // TODO: temporary fix for bad component layout
      window.location.reload()
    } else {
      showMessage('Failed to delete user')
    }
  }

  const deleteUser = async () => {
    const userId = openUserDetails.user._id
    const res = await adminApi.user().delete(userId)
    return res
  }

  const handleAssetUpdated = (updatedAsset) => {
    const updatedAssets = assets.map(asset => asset._id === updatedAsset._id ? updatedAsset : asset)
    setAssets(updatedAssets)
  }

  const handleAssetDeleted = (assetId) => {
    const assetsCopy = [...assets]
    const index = assetsCopy.findIndex((a) => a._id === assetId)
    assetsCopy.splice(index, 1)
    setAssets([...assetsCopy])
  }

  const handleSearchAssets = (searchValue) => {
    setSearchValue(searchValue)
  }

  const searchAssets = async () => {
    const assets = await adminApi.asset().search({ orgId, term: searchValue })
    setAssets(assets)
  }


  const handleSendPasswordResetEmail = async () => {
    toggleProgress()
    try {
      await firebase.auth().sendPasswordResetEmail(openUserDetails.user.email)
      showMessage('Password reset email sent')
      handleCloseUserDetailsDialog()
    } catch (error) {
      console.error(error)
      showMessage('Failed to send email. Try again later')
    } finally {
      toggleProgress()
    }
  }

  const orgLogo = adminApi.image().imageUrl(org.logo, 100, 100)

  return (
    <div
      className={clsx(classes.page, {
        [classes.pageShift]: openDrawer,
      })}
    >
      <div className={classes.organization}>
        {/* TODO : css override */}
        <div
          className="platform_logo platform_left mobilehide"
          style={{
            display: org.logo ? 'block' : 'none',
            background: `url(${orgLogo})center center/contain no-repeat #fff`,
          }}
        />
        <PlatformTop className={classes.platformTop}>
          <div>
            <div className={'title maincolor'} style={{ fontSize: 32 }}>
              {org.name}
            </div>
            <div
              className={appsSelected ? buttonSelected : buttonUnSelected}
              onClick={selectApps}
            >
              Apps
            </div>
            <div
              className={assetsSelected ? buttonSelected : buttonUnSelected}
              onClick={selectAssets}
            >
              Assets
            </div>
            <div
              className={usersSelected ? buttonSelected : buttonUnSelected}
              onClick={selectUsers}
            >
              Users
            </div>
          </div>
        </PlatformTop>
      </div>

      <div style={{ margin: 20 }}>
        {appsSelected &&
          props.apps.map((app, index) => {
            return (
              <App
                key={index}
                app={app}
                addNewClicked={handleAddNewClicked}
                handleAppClick={handleAppClick}
              />
            )
          })}
        {appsSelected && isAppAdmin &&
          <App
            app={{addNew: true}}
            addNewClicked={handleAddNewClicked}
            handleAppClick={handleAppClick}
          />
        }
      </div>

      {assetsSelected && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Search
              placeholder={'Press Enter to Search Assets'}
              search={handleSearchAssets}
            />
            <div
              className="smbutton secondbg"
              style={{ marginRight: 'auto', marginLeft: 30 }}
              onClick={openAddAssetDialog}
            >
              + Add a new asset
            </div>
          </div>
          <AssetsList
            assets={assets}
            orgId={orgId}
            assetClicked={handleAssetClicked}
            addToStory={handleAddToStory}
            fetchMoreAssets={handleFetchMoreAssets}
          />
        </div>
      )}

      {usersSelected && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            className="smbutton secondbg"
            style={{ marginRight: 'auto', marginLeft: 30 }}
            onClick={() => setOpenAddUserDialog(true)}
          >
            + Add a new user
          </div>
          <div style={{ margin: 30 }}>
            <UsersList orgId={orgId} showUserDetails={showUserDetails} />
          </div>
        </div>
      )}
      <div></div>

      <AddAssetDialog
        listedAssetTypes={listedAssetTypes}
        open={addAssetDialogOpen}
        close={() => setAddAssetDialogOpen(false)}
        orgId={orgId}
        toggleProgress={toggleProgress}
        assetsSaved={handleAssetsSaved} />

      <AddUserDialog
        orgId={orgId}
        apps={apps}
        open={openAddUserDialog}
        showMessage={showMessage}
        toggleProgress={toggleProgress}
        handleClose={() => setOpenAddUserDialog(false)}
      />

      {openUserDetails.open && (
        <UserDetailsDialog
          open={openUserDetails.open}
          org={org}
          apps={apps}
          close={handleCloseUserDetailsDialog}
          changeOrgAccess={handleChangeOrgAccess}
          changeAppAccess={handleChangeAppAccess}
          save={handleSaveUserDetails}
          user={openUserDetails.user}
          deleteUser={handleDeleteUser}
          sendPasswordResetEmail={handleSendPasswordResetEmail}
        />
      )}

      {assetSelected.asset && (
        <EditAssetDialog
          orgId={orgId}
          open={assetSelected.open}
          close={() =>
            setAssetSelected({
              open: false,
              asset: defaultAsset,
            })
          }
          asset={assetSelected.asset}
          showMessage={showMessage}
          assetUpdated={handleAssetUpdated}
          assetDeleted={handleAssetDeleted}
          showProgress={() => setShowProgress(true)}
          hideProgress={() => setShowProgress(false)}
          allowDelete={true}
        />
      )}


      <DeleteConfirmationDialog
        open={openDeleteUserDialog}
        close={() => setOpenDeleteUserDialog(false)}
        name={openAddUserDialog.user ? openAddUserDialog.user.name : ''}
        deleteConfirmed={handleDeleteUserConfirmed}
      />

      <ProgressDialog show={showProgress} />

      <Snackbar
        open={openSnackbar}
        close={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </div>
  )
}

export default withStyles(styles)(AppsListPage)
